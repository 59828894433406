<template>
    <div id="store">

        <div class="card">
            <div class="card-body">
                <ul id="elenco-store" class="nav nav-tabs nav-justified nav-tabs-custom" role="tablist">
                    <template v-for="(storeKey, index) in tabKeys">
                        <li class="nav-item">
                            <a :class="[((index === 0) ? 'active' : ''), 'nav-link']" data-toggle="tab" :href="'#' + computeStoreNameKey(storeKey)" @click="searchKeyWord = ''" role="tab" aria-selected="true">
                                <span class="d-none d-md-inline-block text-capitalize">{{storeKey}}</span>
                            </a>
                        </li>
                    </template>
                </ul>

                <div class="tab-content p-3">
                    <template v-for="(storeKey, index) in tabKeys">
                        <div :class="[((index === 0) ? 'active' : ''), 'tab-pane']" :id="computeStoreNameKey(storeKey)" role="tabpanel">
                            <div class="row">
                                <div class="col-md-4 offset-md-8 text-right">
                                    <div class="input-group mb-3">
                                        <input type="text" id="input-search" :class="[((searchKeyWord !== '') ? 'border-right-0' : ''), 'form-control']" @focusin="changeBorderColorDelete(computeColor(true))" @focusout="changeBorderColorDelete(computeColor(false))" v-model="searchKeyWord" :placeholder="$t('store.cercaUnoStore')" aria-describedby="basic-addon2">
                                        <div class="input-group-append" v-if="searchKeyWord !== ''">
                                            <span class="icon-container input-group-text bg-transparent border-left-0 cursorPointer border-color-theme" @click="searchKeyWord = ''" id="delete-search-icon"><i class="mdi mdi-close"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <template v-for="(storeValue, iValue) in storesSearched(storeKey)">
                                    <div :class="[((storeValue.hide) ? 'd-none' : ''), 'col-md-6']">
                                        <!-- Simple card -->
                                        <div class="card bg-light" style="min-height: 500px; height: 650px;">
                                            <img class="card-img-top img-custom" :src="storeValue.url_img" alt="Card image cap">
                                            <div class="card-body p-3">
                                                <div style="min-height: 200px; height: 200px; overflow: auto">
                                                    <h4 class="card-title font-size-16 mt-0">{{storeValue.nome}}</h4>
                                                    <p class="card-text">{{storeValue.desc}} </p>
                                                </div>
                                                <div class="card-fixed-bottom">
                                                    <template v-if="storeValue.sconto ==0 && storeValue.prezzo == 0">
                                                        <h4 class="card-title font-size-18 mt-1 mb-1 text-center text-danger font-weight-bolder">{{$t('store.gratis')}}</h4>
                                                    </template>
                                                    <template v-else>
                                                        <h4 class="card-title font-size-18 mt-1 mb-1 text-center text-danger font-weight-bolder" v-if="storeValue.prezzo > 0">&euro; {{computeStorePrice(storeValue.prezzo)}}  </h4>
                                                        <h4 class="card-title font-size-18 mt-1 mb-1 text-center text-danger font-weight-bolder text-uppercase" v-else-if="storeValue.prezzo < 0">{{$t('store.prezzov')}}</h4>
                                                    </template>
                                                    <a v-if="storeValue.url_sample !== undefined && storeValue.url_sample !== '' && storeValue.url_sample !== null" :href="storeValue.url_sample" class="btn btn-primary waves-effect waves-light btn-sm btn-block btn-rounded mt-4" rel="noopener" target="_blank"><i class="mdi mdi-file"></i> {{$t('store.anteprima')}}</a>
                                                    <a :href="storeValue.link" rel="noopener" class="btn btn-success waves-effect waves-light btn-sm btn-block btn-rounded mt-4" target="_blank"><i class="mdi mdi-cart"></i> {{$t('store.acquista')}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end col -->
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!-- Modal store request error -->
        <div id="modalStoreRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalStoreRequestsErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="modalStoreRequestsErrorLabel">{{$t('store.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body-store-requests-error" class="modal-body">
                        <p id="message-body-store-requests-error"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="button-ok-store" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesNames.HOME })">{{$t('generale.annulla')}}</button>
                        <button type="button" id="button-retry-store" class="btn btn-primary waves-effect waves-light" @click="getStores()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>
        <!-- /Modal store request error -->
    </div>
</template>

<script>
import CustomClient from "@/lib/CustomConcorsandoClient/api/CustomClient";
import _ from 'lodash';
import {routesEnums, themeEnums} from "@/helpers/enums";
import $ from "jquery";
import {promisify} from "../lib/utils";

const customClient = CustomClient.instance
const tabOrder = ["manuali", "corsi", "raccolte quiz", "certificazioni"]

export default {
    name: "Store",
    data () {
        return {
            routesNames: routesEnums,
            stores: [],
            searchKeyWord: ''
        }
    },
    computed: {
        tabKeys() {
            const storeKeys = _.chain(this.stores)
                .filter(s => s.stato === 'Attivo' && s.tipologia)
                .map(x => x.tipologia.toLowerCase())
                .uniq()
                .value()
            const defaultKeys = _.intersection(tabOrder, storeKeys)
            const restKeys = _.difference(storeKeys, tabOrder)
            return [...defaultKeys, ...restKeys]
        },
        storesOrderedAndFiltered() {
            return _.mapKeys(_.groupBy(_.filter(this.stores, (s) => s.stato === 'Attivo' && s.tipologia), 'tipologia'), (v, x) => x.toLowerCase())
        },
        storesSearched: (context) => (storeKey) => {
            if (context.searchKeyWord !== "") {
                return _.map(Object.values(context.storesOrderedAndFiltered[storeKey]), (s) => {
                    let hide = true
                    if ((s.nome !== undefined && s.nome !== null && s.nome.toLowerCase().includes(context.searchKeyWord.toLowerCase())) || (s.desc !== undefined && s.desc !== null && s.desc.toLowerCase().includes(context.searchKeyWord.toLowerCase()))) {
                        hide = false
                    }
                    return {...s, hide}
                })
            } else {
                return _.map(Object.values(context.storesOrderedAndFiltered[storeKey]), (s) => ({...s, hide: false}))
            }
        }
    },
    mounted() {
        this.getStores()
    },
    methods: {
        getStores () {
            this.$emit('is-components-ready', 'store', false)
            promisify((...args) => customClient.getStore(...args), undefined)
                .then((res) => {
                    this.$emit('is-components-ready', 'store', true)
                    this.stores = _.cloneDeep(res)
                })
                .catch(() => {
                    this.showModalErrorBlocker('store.modale.errore.negozi')
                })
        },
        storePriceDiscounted (store) {
            if (!isNaN(store.prezzo)) {
                return this.computeStorePrice((store.prezzo - (store.prezzo * store.sconto / 100)))
            } else {
                return this.computeStorePrice(store.prezzo)
            }
        },
        showModalErrorBlocker (message) {
            const context = this
            this.$emit('is-components-ready', 'store', true)
            $('#modalStoreRequestsError').modal({backdrop: 'static', keyboard: false})
            $('#message-body-store-requests-error').html(context.$t(message))
        },
        changeBorderColorDelete (color) {
            $('#delete-search-icon').css('border', '1px solid ' + color)
        },
        computeStoreNameKey (key) {
            return key.toLowerCase().replaceAll(" ", "")
        },
        computeStorePrice (price) {
            return parseFloat(price).toFixed(2)
        },
        computeColor (statusFocus) {
            if (statusFocus) {
                if (localStorage.getItem('theme') === themeEnums.LIGHT) {
                    return '#b1bbc4'
                } else {
                    return '#2f3e46'
                }
            } else {
                if (localStorage.getItem('theme') === themeEnums.LIGHT) {
                    return '#ced4da'
                } else {
                    return '#2f3e46'
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
    html.theme-light {
        .border-color-theme {
            border-color: #b1bbc4!important;
        }
    }

    html.theme-dark {
        .border-color-theme {
            border-color: #2f3e46!important;
        }
    }

    html.theme-light, html.theme-dark {
        .img-custom {
            width: auto !important;
            height: 250px;
            object-fit: contain;
        }

        .card-fixed-bottom {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
</style>
